import { useState } from "react";
import { useSearchParams } from 'react-router-dom';

const CostDropDown = ({setUtilities, updateResults}) => {
    const [showError, setShowError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const min = searchParams.get('min') ? searchParams.get('min') : '';
    const max = searchParams.get('max') ? searchParams.get('max') : '';

    const updateCostFilter = (costType, value) => {
        setShowError(false);
        const regex = /^(\d*\.?\d{0,2})$/;
        if (value === '') {
            setSearchParams(prev => {
                prev.delete(costType);
                return prev;
            })
        } else if (regex.test(value)) {
            setSearchParams(params => {
                params.set(costType, value);
                return params;
            });
        } else {
            setShowError(true)
        }
        
        setSearchParams(prev => {
            prev.delete('page');
            return prev;
        })
        const results = updateResults(searchParams);
        setUtilities(results);
    }


    return (
        <div className="cost-filter-container">
            <h2>Cost</h2>
            <div>
                <div className="cost-input-container">
                    <strong>Minimum</strong>
                    <label className="flex-row-btwn cost-input-label">¢ 
                        <input type="text" 
                        placeholder="No Min" 
                        onChange={(e) => updateCostFilter("min", e.target.value)}
                        value={min}
                        className="cost-input"></input>
                        per kWh
                    </label>
                </div>
                <div className="cost-input-container">
                    <strong>Maximum</strong>
                    <label className="flex-row-btwn cost-input-label">¢
                        <input type="text"
                            placeholder="No Max"
                            onChange={(e) => updateCostFilter("max", e.target.value)}
                            value={max}
                            className="cost-input"></input>
                        per kWh
                    </label>
                </div>
                {showError && <div className="red-text small-text">Inputs must be a number with two decimal places</div>}
            </div>
        </div>
    )
}

export default CostDropDown