import { NavLink } from "react-router-dom";
import DateSpan from "../../Shared/DateSpan";
import ProgramList from "./UtitlityItemProgramList";

const UtilityIndexItem = ({ utility, allPrograms }) => {
    const getProgramSummary = loadZone => {
        let programSummary = <p>No Green Pricing Programs</p>;
        const prodPrograms = loadZone.programs.some(p => p.production)
        if ((!allPrograms && prodPrograms) || (allPrograms && loadZone.programs.length > 0)) {
            programSummary = <ProgramList programs={loadZone.programs} allPrograms={allPrograms}/>
        }
        return programSummary;
    }

    return (
        <NavLink to={`/utilities/${utility._id}`} className='utility-item-container'>
            <h2 className="utility-item-header">{`${utility.name} (${utility._id})`}</h2>
            <div className="utility-item-summary-container box-shadow">
                {utility.loadZones.map((loadZone) => (
                    <div key={`${utility._id}${loadZone._id}`} className='utility-item'>
                        <div className="utility-item-lz-header">
                            <p><span className="utility-item-state">{`${loadZone.region}`}</span></p>
                            <p><span className="utility-item-state">{`${loadZone.aliases.join(', ')}`}</span></p>
                            {/* <p className='shaded-text small-text'>Updated: <DateSpan timestamp={loadZone.lastUpdated}/></p> */}
                        </div>
                        <div className="utility-item-summary-lz">
                            <p>{`${loadZone.customers.toLocaleString()} customers`}</p>
                            {loadZone.programs && getProgramSummary(loadZone)}
                            
                            {loadZone.retail && <>
                                <br></br>
                                <div className="flex-row-btwn">
                                    <h2>Retail Utility</h2>
                                    <p className='shaded-text small-text'>Updated: <DateSpan timestamp={loadZone.retail.lastUpdated} /></p>
                                </div>
                            </>}
                        </div>
                    </div>
                ))}
            </div>
        </NavLink>
    )
}

export default UtilityIndexItem;