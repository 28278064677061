import {Modal} from '../../context/Modal'


function LoadingModal() {
    return (
        <Modal>
            <p className='white-text'>Just a moment...</p>
            <div className="loading-container">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </Modal>
    )
}

export default LoadingModal;