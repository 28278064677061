import { PiDotDuotone } from 'react-icons/pi'

const List = ({ listItems }) => {
    return (
        <ul>
            {listItems.map(item => (
                <li key={item}><PiDotDuotone />{item}</li>
            ))}
        </ul>
    )
}

export default List;