import { useState } from "react";
import SortItem from './SortItem';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const SortOptions = ({setUtilities, updateResults, searchParams}) => {
    const [show, setShow] = useState(false)
    const toggle = () => setShow(!show);
    const options = [
        { text: "Customers", isAlpha: false },
        { text: "Updated Date", isAlpha: false },
        { text: "Utility Name", isAlpha: true }
    ];

    return (
        <div className={searchParams.get('sort') ? "drop-down-button highlight-button" : "drop-down-button"}>
            <div onClick={() => toggle()} className="drop-down-name white-text flex-row-btwn filters-button">
                <div> {show ? <FaCaretUp /> : <FaCaretDown />}</div>
                <div className="margin-left margin-right">Sort {searchParams.get('sort') ? `by: ${searchParams.get('sort') }` : ''}</div>
            </div>
            {show && <div className="drop-down-items-container sort-adjust">
                {options.map(field => (
                    <div key={field.text}>
                        <SortItem
                            key={`${field.text}asc`}
                            text={field.text}
                            isAlpha={field.isAlpha}
                            isAsc={true}
                            setUtilities={setUtilities} 
                            updateResults={updateResults}
                        />
                        <SortItem 
                            key={`${field.text}desc`}
                            text={field.text}
                            isAlpha={field.isAlpha}
                            isAsc={false}
                            setUtilities={setUtilities}
                            updateResults={updateResults}
                        />
                    </div>
                ))}
                <SortItem
                    text={'Default'}
                    isAlpha={false}
                    isAsc={true}
                    setUtilities={setUtilities}
                    updateResults={updateResults}
                />
            </div>}
        </div>
    )
}

export default SortOptions;