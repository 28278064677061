import moment from 'moment';

const DateSpan = ({ timestamp, addDays=0 }) => {
    const localDate = moment(timestamp).add(addDays, 'days').format('M/D/YYYY');

    return (
        <span>{localDate}</span>
    )
};

export default DateSpan;