export const getSearchAndFilterFunction = (allUtilities) => {
    return (searchParams) => {
        if (searchParams.size === 0) return allUtilities;
        let results = [...allUtilities];

        if (!searchParams.get('allPrograms')) {
            results = results.map(utility => {
                let filteredUtility = { ...utility };
                filteredUtility.loadZones = [];
                for (const lz of utility.loadZones) {
                    const filteredLoadZones = { ...lz };
                    filteredLoadZones.programs = filteredLoadZones.programs.filter(p => p.production === true);
                    filteredUtility.loadZones.push(filteredLoadZones);
                }
                // if (utility._id === '4265') console.log(filteredUtility)
                return filteredUtility;
            })
        }
        // results.forEach(u => {
        //     if (u._id === '4265') console.log(u)
        // })
        searchParams.forEach((value, key) => {
            results = filterResults(results, key, value);
        })

        const sortOption = searchParams.get('sort');

        if (sortOption && sortOption !== 'Default-asc') {
            results = sortResults(results, sortOption)
        }
        console.log(results);
        return results;
    }
}

const sortResults = (results, sortOption) => {
    let sorted;

    switch (sortOption) {
        case "UtilityName-asc":
            sorted = results.sort((a, b) => a.name > b.name ? 1 : -1);
            break;
        case "UtilityName-desc":
            sorted = results.sort((a, b) => a.name > b.name ? -1 : 1);
            break;
        case "Customers-asc":
            sorted = results.sort((a, b) => minCustomers(a.loadZones) > minCustomers(b.loadZones) ? 1 : -1);
            break;
        case "Customers-desc":
            sorted = results.sort((a, b) => maxCustomers(a.loadZones) > maxCustomers(b.loadZones) ? -1 : 1);
            break;
        case "UpdatedDate-asc":
            sorted = results.sort((a, b) => minMaxDate(a.loadZones, true) > minMaxDate(b.loadZones, true) ? 1 : -1);
            break;
        case "UpdatedDate-desc":
            sorted = results.sort((a, b) => minMaxDate(a.loadZones, false) > minMaxDate(b.loadZones, false) ? -1 : 1);
            break;
        default:
            break;
    }

    return sorted;
}

const minCustomers = (arr) => {
    const min = arr.reduce((acc, lz) => Math.min(lz.customers, acc), Infinity);
    return min;
}

const maxCustomers = (arr) => {
    const max = arr.reduce((acc, lz) => Math.max(lz.customers, acc), 0);
    return max;
}

const minMaxDate = (loadZones, isMin) => {
    let dates = [];

    loadZones.forEach(lz => {
        lz.programs.forEach(p => {
            dates.push(p.lastUpdated)
        })
        if (lz?.retail) dates.push(lz.retail.lastUpdated)
    })

    if (isMin) return dates.reduce((a, b) => a < b ? a : b, '9999-03-23T05:00:00.000Z');
    return dates.reduce((a, b) => a > b ? a : b, '1900-03-23T05:00:00.000Z');
}

const filterResults = (results, key, value) => {
    let filtered = [...results];

    switch (key) {
        case "search":
            filtered = filtered.filter(u => search(u, value));
            break;
        case "retail":
            filtered = filtered.filter(u => u.loadZones.some(lz => lz.retail));
            break
        case "CleanChoice":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p.name === "CleanChoice Energy"));
            break
        case "gpp":
            filtered = filtered.filter(u => u.loadZones.some(lz => lz.programs.length > 0));
            break
        case "nogpp":
            filtered = filtered.filter(u => u.loadZones.some(lz => lz.programs.length === 0));
            break
        case "outOfProd":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p.production === false));
            break
        case "min":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => costFilter(p.cost, "min", value)));
            break
        case "max":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => costFilter(p.cost, "max", value)));
            break
        case "blocks":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p?.blocks));
            break
        case "credit":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p?.credit));
            break
        case "waitlisted":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p.signUpInfo?.barriers.includes("Waitlist")));
            break
        case "termination":
            filtered = filtered.filter(u => checkPrograms(u.loadZones, p => p.contracts.some(c => c?.notes)));
            break
        case "recs":
            filtered = filtered.filter(u => checkCerts(u.loadZones, "Recs Retired"));
            break
        case "revenue":
            filtered = filtered.filter(u => checkCerts(u.loadZones, "Revenue Neutral"));
            break
        case "bcorp":
            filtered = filtered.filter(u => checkCerts(u.loadZones, "B-Corp"));
            break
        case "greene":
            filtered = filtered.filter(u => checkCerts(u.loadZones, "Green-e"));
            break
        case "onetime":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 0));
            break
        case "monthly":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 1));
            break
        case "3months":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 3));
            break
        case "1year":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 12));
            break
        case "5year":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 60));
            break
        case "10year":
            filtered = filtered.filter(u => checkContracts(u.loadZones, 120));
            break
        default:
            break;
    }
    return filtered;
}

const checkPrograms = (loadZones, cb) => {
    for (const lz of loadZones) {
        if (lz.programs.length && lz.programs.some(cb)) return true;
    }
    return false;
}

// const regions = {
//     '0': new Set('AK', 'CT', 'MA', 'ME', 'NH', 'NJ', 'RI', 'VT'),
//     '1': new Set('DE', 'PA', 'NY'),
//     '2': new Set('DC', 'MD', 'NC', 'SC', 'VA', 'WV'),
//     '3': new Set('AL', 'FL', 'GA', 'MS', 'TN'),
//     '4': new Set('IN', 'MI', 'KY', 'OH'),
//     '5': new Set('IA', 'MN', 'MT', 'ND', 'SD', 'WI'),
//     '6': new Set('IL', 'KS', 'MO', 'NE'),
//     '7': new Set('AR', 'LA', 'OK', 'TX'),
//     '8': new Set('AZ', 'CO', 'ID', 'NM', 'NV', 'UT', 'WY'),
//     '9': new Set('AK', 'CA', 'HI', 'OR', 'WA')
// }

const search = (utility, text) =>
    utility._id.includes(text) ||
    utility.name.toLowerCase().includes(text.toLowerCase()) ||
    utility.loadZones.some(lz => lz.aliases.some(a => a.toLowerCase().includes(text.toLowerCase()))) ||
    checkPrograms(utility.loadZones, p => p.name.toLowerCase().includes(text.toLowerCase())) ||
    utility.loadZones.some(lz => lz.zips.includes(text));
// utility.loadZones.some(lz => regions[text.slice(0, 1)] && regions[text.slice(0, 1)].has(lz.region) && lz.zips.includes(text));

const checkCerts = (loadZones, certType) => {
    return checkPrograms(loadZones, p => p.certifications.includes(certType));
}

const checkContracts = (loadZones, length) => {
    return checkPrograms(loadZones, p => p.contracts.some(c => c.length === length))
}

const costFilter = (programCost, costType, costInput) => {
    const costDataType = programCost instanceof Array ? "array" : "string";
    const key = costType + costDataType;

    switch (key) {
        case "minstring":
            return programCost >= costInput;
        case "minarray":
            return programCost.some(c => c >= costInput);
        case "maxstring":
            return programCost <= costInput;
        case "maxarray":
            return programCost.some(c => c <= costInput);
        default:
            break;
    }
}