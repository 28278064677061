import { useSearchParams } from "react-router-dom";
import UtilityIndexItem from "./UtilityIndexItem";
import Pagination from '@mui/material/Pagination';

const Utilities = ({ show, shownUtilities, allPrograms }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? parseInt(searchParams.get('page')) : 1;
    const pageCount = Math.ceil(shownUtilities.length / 50);
    const utilitiesSlice = shownUtilities.slice((page - 1 ) * 50, page * 50);

    const updatePage = (event, value) => {
        // console.log(value);
        setSearchParams(prev => {
            prev.set('page', value);
            return prev;
        })
    }

    return (
        <div className={"utility-index-results-container"} style={{ top: show ? `380px` : `150px` }}>
            <div className="pagination-container"><Pagination count={pageCount} page={page} onChange={updatePage} /></div>
            {utilitiesSlice.map((utility) => (
                <UtilityIndexItem key={`${utility._id}-${utility.name}`} utility={utility} allPrograms={allPrograms} />
                ))}
            <div className="pagination-container"><Pagination count={pageCount} page={page} onChange={updatePage} /></div>
        </div>
    )
}

export default Utilities;