import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUtility } from "../../../store/utilities";
import { CgClose } from 'react-icons/cg'
import LoadingModal from "../../Shared/LoadingModal";
import LoadZone from "./LoadZone";
import NavBar from "../../Shared/NavBar";


const UtilityShow = () => {
    const loggedIn = false;
    const dispatch = useDispatch();
    const {utilityId} = useParams();
    const utility = useSelector(state => state.utilities[utilityId]);
    const [showAddLoadZone, setShowAddLoadZone] = useState(false)

    // const emptyLoadZone = {
    //     region: '',
    //     aliases: [],
    //     customers: 0,
    //     zips: []
    // }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        if (!utility) {
            dispatch(fetchUtility(utilityId))
        }
    }, [dispatch, utilityId, utility])

    return (
        <div className="utility-show-container">
            <div className="utility-index-header-container box-shadow" style={{ height: `150px` }}>
                <NavBar />
                {/* <NavLink to={'/utilities'} className="navlink"><FaArrowLeft /> Back to all Utilities</NavLink> */}
                <br></br>
                <br></br>
                {utility && <h1 className='utility-show-header'>{utility.name}</h1>}
            </div>
            <div className="utility-show-loadzones">
                {utility && utility.loadZones.map((loadZone) => (
                    <LoadZone key={loadZone["_id"]} loadZone={loadZone} />
                ))}
                {loggedIn && !showAddLoadZone && <div className="button top-margin" onClick={() => setShowAddLoadZone(!showAddLoadZone)}>Add Load Zone</div>}
                {showAddLoadZone &&
                    <div className="retail-utility-container box-shadow">
                        <div className="retail-utility-header">
                            <h2 className="retail-utility-header-column">Add Load Zone</h2>
                            <div onClick={() => setShowAddLoadZone(false)}><CgClose className="close" /></div>
                        </div>
                    </div>}
            </div>
            {!utility && <LoadingModal />}
        </div>
    )
}

export default UtilityShow;