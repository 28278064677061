import { useState } from "react";
import DateSpan from "../../Shared/DateSpan";
import List from "../../Shared/List";
import { FaEdit } from 'react-icons/fa'
import './Programs.scss'
import Contract from "./Contract";

const Program = ({program}) => {
    const loggedIn = false;
    const [showEdit, setShowEdit] = useState(false);
    const isOutOfProd = program.production === false;

    const getUrl = (program) => (
        <a target="_blank" rel="noreferrer" href={program.contact.url}>{program.contact.url}</a>
    )
    return (
        <div className="program-container">
            <div className="retail-utility-header">
                <h2 className="gpp-name retail-utility-header-column green-text">{showEdit ? `Edit ` : ''}{isOutOfProd ? `${program.name} (not in production)` : program.name }</h2>
                <div className="retail-utility-header-column flex-row-btwn">
                    {!showEdit && <p className="right-justified shaded-text">Updated <DateSpan timestamp={program.lastUpdated} /></p>}
                    {loggedIn && <div className="edit-button" onClick={() => setShowEdit(!showEdit)}><FaEdit /></div>}
                </div>
            </div>
            <div>
                <h4 className="table-header rounded-top">Pricing & Buying Options</h4>
                <div className="table-container">
                    <p className="table-header-item green-shaded">Cost</p>
                    <p className="table-header-item green-shaded">Credit</p>
                    <p className="table-header-item green-shaded">Percentages Options</p>
                    <p className="table-header-item green-shaded">Percentages Range</p>
                    <p className="table-header-item green-shaded">Blocks (min)</p>
                    <p className="table-header-item green-shaded">Blocks (max)</p>
                    <p className="table-header-item green-shaded">Blocks (size)</p>
                </div>
                <div className="table-container">
                    <p className="table-item full">{`¢ ${typeof program.cost === "object" ? program.cost.join(" - ") : program.cost} per kWh`}</p>
                    <p className="table-item full">{program?.credit ? `¢ ${program.credit} per kWh` : ''}</p>
                    <p className="table-item full">{program.percentages?.options ? program.percentages.options.join(", ") : ''}</p>
                    <p className="table-item full">{program.percentages?.range ? program.percentages.range.join(" - ") : ''}</p>
                    <p className="table-item full">{program.blocks?.min ? program.blocks.min : ''}</p>
                    <p className="table-item full">{program.blocks?.max ? program.blocks.max : ''}</p>
                    <p className="table-item full">{program.blocks?.size ? program.blocks.size.join(', ') : ''}</p>
                </div>
            </div>
            <div>
                <h4 className="table-header rounded-top">Sign Up Info</h4>
                <div className="table-row">
                    <p className="table-col-header green-shaded">Button Text</p> 
                    <p className="table-row-item">{program.signUpInfo.button}</p>
                </div>
                <div className="table-row">
                    <p className="table-col-header green-shaded">Link</p>
                    <p className="table-row-item"><a target="_blank" rel="noreferrer" href={program.signUpInfo.url}>{program.signUpInfo.url}</a></p>
                </div>
                <div className="table-row">
                    <p className={program.signUpInfo.barriers.length ? "table-col-header green-shaded" : "table-col-header green-shaded bottom-left-rad"}>FAQ</p>
                    <p className={program.signUpInfo.barriers.length ? "table-row-item" : "table-row-item bottom-right-rad"}><a target="_blank" rel="noreferrer" href={program.signUpInfo.faq}>{program.signUpInfo.faq}</a></p>
                </div>
                <>
                    <h4 className="table-header">Barriers</h4>
                    <div className="table-container">
                        <p className={program.signUpInfo?.barriers.includes("Waitlist") ? "table-item full green-shaded" : "table-item full shaded-text"}>Waitlist</p>
                        <p className={program.signUpInfo?.barriers.includes("Sign In") ? "table-item full green-shaded" : "table-item full shaded-text"}>Sign In</p>
                        <p className={program.signUpInfo?.barriers.includes("Account Number") ? "table-item full green-shaded" : "table-item full shaded-text"}>Account Number</p>
                        <p className={program.signUpInfo?.barriers.includes("Printed") ? "table-item full green-shaded" : "table-item full shaded-text"}>Printed</p>
                        <p className={program.signUpInfo?.barriers.includes("Contract Required") ? "table-item full green-shaded" : "table-item full shaded-text"}>Contract Required</p>
                    </div>
                </>
            </div>
            <div>
                <h4 className="table-header rounded-top">Contact</h4>
                <div className="table-container">
                    <p className="table-header-item green-shaded" id="phone">Phone</p>
                    <p className="table-header-item green-shaded" id="email">Email</p>
                    <p className="table-header-item green-shaded" id="url">url</p>
                </div>
                <div className="table-container">
                    <p className="table-item" id="phone">{program.contact.phone ? program.contact.phone : '-'}</p>
                    <p className="table-item" id="email">{program.contact.email ? program.contact.email : '-'}</p>
                    <p className="table-item" id="url">{program.contact.url ? getUrl(program) : '-'}</p>
                </div>
            </div>
            <div>
                <h4 className="table-header rounded-top">Sources</h4>
                <div className="table-container">
                    <p className="table-header-item green-shaded">Wind</p>
                    <p className="table-header-item green-shaded">Solar</p>
                    <p className="table-header-item green-shaded">Bio</p>
                    <p className="table-header-item green-shaded">Hyrdo</p>
                    <p className="table-header-item green-shaded">Geo</p>
                    <p className="table-header-item green-shaded">Other</p>
                </div>
                <div className="table-container">
                    <p className="table-item full">{program.sources.wind ? `${program.sources.wind * 100}%` : ''}</p>
                    <p className="table-item full">{program.sources.solar ? `${program.sources.solar * 100}%` : ''}</p>
                    <p className="table-item full">{program.sources.bio ? `${program.sources.bio * 100}%` : ''}</p>
                    <p className="table-item full">{program.sources.hydro ? `${program.sources.hydro * 100}%` : ''}</p>
                    <p className="table-item full">{program.sources.geo ? `${program.sources.geo * 100}%` : ''}</p>
                    <p className="table-item full">{program.sources.other ? `${program.sources.other * 100}%` : ''}</p>
                </div>
            </div>
            <div>
                <h4 className="table-header rounded-top">Certifications</h4>
                <div className="table-container">
                    <p className={program.certifications.includes("Recs Retired") ? "table-item full green-shaded" : "table-item full shaded-text"}>Recs Retired</p>
                    <p className={program.certifications.includes("Revenue Neutral") ? "table-item full green-shaded" : "table-item full shaded-text"}>Revenue Neutral</p>
                    <p className={program.certifications.includes("B-Corp") ? "table-item full green-shaded" : "table-item full shaded-text"}>B-Corp</p>
                    <p className={program.certifications.includes("Green-e") ? "table-item full green-shaded" : "table-item full shaded-text"}>Green-e</p>
                </div>
            </div>
            {program.contracts.length && <div>
                <h4 className="table-header rounded-top">Contracts</h4>
                <div className="table-container">
                    <p className="table-header-item green-shaded" id="narrow">Rate Type</p>
                    <p className="table-header-item green-shaded" id="narrow">Length</p>
                    <p className="table-header-item green-shaded" id="narrow">Minimum Length</p>
                    <p className="table-header-item green-shaded" id="wide">Notes</p>
                </div>
                {program.contracts.map(contract => (
                    <Contract contract={contract} key={`${program}-${contract.length}`}/>
                ))}
            </div>}
            <div className="top-margin">
                <h4>Public Notes</h4>
                {program.notes ? <List listItems={program.notes}/> : <p>None</p>}
            </div>
            <div className="top-margin">
                {/* TODO hide if not logged in?? */}
                <h4>Research Notes</h4>
                {program.researchNotes ? <List listItems={program.researchNotes} /> : <p>None</p>}
            </div>
        </div>
    )
}

export default Program;