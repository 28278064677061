const Contract = ({contract}) => {
    // rateType -> all set to Fixed right now? worth showing?
    // length -> 
    // min -> optional, same format as length, but all defaulted to 12?
    // notes -> optional
    const getLength = (months) => {

        const lengths = {
            1: "Monthly",
            3: "3 Month",
            12: "Annual",
            60: "5 Years",
            120: "10 Years"
        }
        return lengths[months];
    }


    return (
        <>
            <div className="table-container">
                <p className="table-item" id="narrow">{contract.rateType}</p>
                <p className="table-item" id="narrow">{getLength(contract.length)}</p>
                <p className="table-item" id="narrow">{getLength(contract.min)}</p>
                <p className="table-item" id="wide">{contract.notes}</p>
            </div>
        </>
    )
}


export default Contract;