import { NavLink } from "react-router-dom";
import { BiLogOut } from 'react-icons/bi'

const NavBar = () => {
    const loggedIn = false
    return (
        <div className="navbar-container">
            <NavLink to={'/utilities'} className='utility-index-header'><h1>Green Neighbor Challenge Utilities</h1></NavLink> 
            {loggedIn && <NavLink to="/" className="navlink"><BiLogOut /> Logout</NavLink>}
        </div>
    )
}

export default NavBar;