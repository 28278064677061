import { useState } from "react";
import Program from "./Program";
import RetailUtility from "./RetailUtility";
import EditRetail from "./EditForm/EditRetail";
import {CgClose} from 'react-icons/cg'


const LoadZone = ({ loadZone }) => {
    const loggedIn = false;
    const [showAddRetail, setShowAddRetail] = useState(false)
    const emptyRetail = {
        prices: [['','']],
        link: '',
        notes: []
    }
    
    return (
        <div className="lz-container">
            <div className="lz-header">
                <div className="lz-header-right">
                    <h2 className="gpp-name">{loadZone.region}</h2>
                    {loadZone.aliases.length > 0 && <p className="lz-header-aliases">{`(${loadZone.aliases.join(', ')})`}</p>}
                </div>
                <p>{`${loadZone.customers.toLocaleString() } Customers`}</p>
                {/* <p className="shaded-text">Updated: <DateSpan timestamp={loadZone.lastUpdated}/></p> */}
            </div>

            {loadZone.retail ? <RetailUtility retail={loadZone.retail}/> : 
            <div>
                {loggedIn && !showAddRetail && <div className="button top-margin" onClick={() => setShowAddRetail(!showAddRetail)}>Add Retail</div>}
                {showAddRetail && 
                <div className="retail-utility-container box-shadow">
                    <div className="retail-utility-header">
                        <h2 className="retail-utility-header-column">Add Retail</h2>
                        <div onClick={() => setShowAddRetail(false)}><CgClose className="close" /></div>
                    </div>
                    <EditRetail
                        retail={emptyRetail}
                        isAdd={false}
                    />
                </div>}
            </div>}
            {loadZone.programs.length === 0 && <p>No Green Pricing Programs</p>}
            {loadZone.programs.length > 0 && <div>
                <h2 className="utility-item-header top-margin">Green Pricing Programs</h2>
                <div className="gpp-container">
                    {loadZone.programs.map((program) => (
                        <Program key={program._id} program={program} />
                    ))}
                </div>
            </div>}

        </div>
    )
}

export default LoadZone;