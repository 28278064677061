import { createSelector } from 'reselect';

const RECEIVE_UTILITIES = 'utilities/RECEIVE_UTILITIES'
const RECEIVE_UTILITY = 'utilities/RECEIVE_UTILITY'
const RECEIVE_UTILITIES_ERRORS = 'utilities/RECEIVE_UTILITIES_ERRORS'
const CLEAR_UTILITY_ERRORS = "utilities/CLEAR_UTILITY_ERRORS";


const receiveUtilities = utilities => ({
    type: RECEIVE_UTILITIES,
    utilities
})

const receiveUtility = utility => ({
    type: RECEIVE_UTILITY,
    utility
})

const receiveErrors = errors => ({
    type: RECEIVE_UTILITIES_ERRORS,
    errors
});

const selectUtilities = state => state.utilities;

export const getUtilities = createSelector([selectUtilities], utilities => {
    return Object.values(utilities);
})

export const fetchUtility = id => async dispatch => {
    try {
        const res = await fetch(`/api/utilities/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const utility = await res.json();
        dispatch(receiveUtility(utility));
    } catch (err) {
        const resBody = await err.json();
        if (resBody.statusCode === 400) {
            return dispatch(receiveErrors(resBody.errors));
        }
    }
}

export const fetchUtilities = () => async dispatch => {
    try {
        const res = await fetch(`/api/utilities`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })

        const utilities = await res.json();
        dispatch(receiveUtilities(utilities))
    } catch (err) {
        const resBody = await err.json();
        if (resBody.statusCode === 400) {
            dispatch(receiveErrors(resBody.errors));
        }
    }
}

const nullErrors = null;


export const utilityErrorsReducer = (state = nullErrors, action) => {
    switch (action.type) {
        case RECEIVE_UTILITIES_ERRORS:
            return action.errors;
        case CLEAR_UTILITY_ERRORS:
            return nullErrors;
        default:
            return state;
    }
}


export const utilityReducer = (state = {}, action) => {
    const newState = { ...state };
    switch (action.type) {
        case RECEIVE_UTILITIES:
            return { ...action.utilities }
        case RECEIVE_UTILITY:
            newState[action.utility._id] = action.utility ;
            return newState;
        default:
            return state;
    }
}