import { LuLeaf, LuEyeOff } from "react-icons/lu";
import DateSpan from "../../Shared/DateSpan";

const ProgramList = ({programs, allPrograms}) => {
    const prodPrograms = programs.filter(p => p.production === true);
    const notProdPrograms = programs.filter(p => p.production === false);

    const getDisplayText = (program) => {
        if (program.name !== '') {
            return program.name;
        } else if (program.contact?.url) {
            return `${program.contact.url.substring(8, 30)}...`
        }

        return 'No name'
    }
    return (
        <div>
            <br></br>
            {prodPrograms.length > 0 && <>
                <h2 className="green-text">Green Pricing Programs</h2>
                <ul>
                    {prodPrograms.map(p => (
                        <li key={p._id} className="utility-index-gpp small-text space-between">
                            <div><LuLeaf /><span className="utility-index-item-region-span">{p.name}</span></div>
                            <p className='shaded-text'>Updated: <DateSpan timestamp={p.lastUpdated} /></p>
                        </li>
                    ))}
                </ul>
            </>}
            {allPrograms && notProdPrograms.length > 0 && <>
                <h2 className="shaded-text">Programs not in Production</h2>
                <ul className="shaded-text">
                    {notProdPrograms.map(p => (
                        <li key={p._id} className="utility-index-gpp small-text space-between">
                            <div><LuEyeOff /><span className="utility-index-item-region-span">{getDisplayText(p)}</span></div>
                            <p className='shaded-text'>Updated: <DateSpan timestamp={p.lastUpdated} /></p>
                        </li>
                    ))}
                </ul>
            </>}
        </div>
    )
}

export default ProgramList;