import { useState } from "react";
import DateSpan from "../../Shared/DateSpan";
import List from "../../Shared/List";
import {FaEdit} from 'react-icons/fa'
import EditRetail from "./EditForm/EditRetail";

const RetailUtility = ({retail, isAdd}) => {
    const loggedIn = false;
    const [showEdit, setShowEdit] = useState(isAdd);
    const prices = retail.prices.slice().reverse();

    return (
        <div className="retail-utility-container box-shadow">
            <div className="retail-utility-header">
                <h2 className="retail-utility-header-column">{showEdit ? 'Edit Retail Details' :`Retail Details`}</h2>
                <div className="retail-utility-header-column flex-row-btwn">
                    {!showEdit && <p className="right-justified shaded-text">Updated <DateSpan timestamp={retail.lastUpdated} /></p>}
                    {loggedIn && <div className="edit-button" onClick={() => setShowEdit(!showEdit)}><FaEdit /></div>}
                </div>
            </div>
            {showEdit && <EditRetail retail={retail} isAdd={isAdd}/>}
            {!showEdit && <>
                <p><strong>Most Recent Price:</strong>{` ${prices[0][1]} `}(<DateSpan timestamp={prices[0][0]} addDays={1} />)</p>
                <p><strong>Link:</strong> <a target="_blank" rel="noreferrer" href={retail.link}>{retail.link}</a></p>
                <h3>Notes</h3>
                <List listItems={retail.notes} />
                <h4 className="table-header rounded-top"> Historical Prices</h4>
                <div className="table-container">
                    <p className="table-header-item green-shaded">Month</p>
                    <p className="table-header-item green-shaded">Price</p>
                </div>
                    {prices.map(price => (
                        <div key={`${price[0]}${price[1]}`} className="table-container">
                            <p className="table-item"><DateSpan timestamp={price[0]} addDays={1} /></p>
                            <p className="table-item">{price[1]}</p>
                        </div>
                    ))}
            </>}
        </div>
    )
}

export default RetailUtility;