import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUtilities, getUtilities } from "../../../store/utilities";
import { getSearchAndFilterFunction } from './Search/searchFunctions';
import { FaCaretDown, FaCaretUp, FaFilter } from 'react-icons/fa';
import CostDropDown from "./Search/CostDropDown";
import DropDown from "./Search/DropDown";
import NavBar from "../../Shared/NavBar";
import SortOptions from "./Search/SortOptions";
import LoadingModal from "../../Shared/LoadingModal";
import CheckBox from "./Search/Checkbox";
import TextSearch from "./Search/TextSearch";
import Utilities from "./Utilities";
import '../Utilities.scss'
import './Search/search.scss'

const UtilityIndex = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const utilities = useSelector(getUtilities);
    const [show, setShow] = useState(false);
    const [shownUtilities, setShownUtilities] = useState(utilities);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchText = searchParams.get("search") ? searchParams.get("search") : '';
    const page = searchParams.get('page') ? searchParams.get('page') : 1;
    const allPrograms = searchParams.get("allPrograms") === 'true' ? true : false;
    const updateResults = getSearchAndFilterFunction(utilities);

    const updateSearch = async (inputText) => {

        if (inputText === '') {
            setSearchParams(prev => {
                prev.delete('search');
                return prev;
            })
        } else if (inputText.length && searchText !== inputText) {
            setSearchParams(params => {
                params.set("search", inputText);
                return params;
            });
        }
        
        setSearchParams(prev => {
            prev.delete('page');
            return prev;
        })

        if (!(inputText.length === 0 && shownUtilities.length === utilities.length)) {
            const results = await updateResults(searchParams);
            setShownUtilities(results);
        }
    }

    useEffect(() => {
        if (utilities.length <= 1) {
            dispatch(fetchUtilities(!allPrograms));
        }
        if (searchText === '') {
            setSearchParams(prev => {
                prev.delete('search', '');
                return prev;
            })
        } 
        const results = updateResults(searchParams);
        setShownUtilities(results);

    }, [dispatch, utilities])

    const clearFilters = () => {
        const params = searchText === '' ? '' : `?search=${searchText}`
        navigate(`/utilities${params}`);
        setShownUtilities(utilities);
    };

    return (
        <div className="utility-index-container">
            <div className="utility-index-header-container box-shadow" style={{ height: show ? `380px` : `150px` }}>
                <NavBar />
                <div className="white-text search-options">
                    <div className="flex-row-btwn width100">
                        <TextSearch searchText={searchText} updateSearch={updateSearch}/> 
                        <div className={searchParams.size - (searchParams.get('sort') ? 1 : 0) - (searchParams.get('search') ? 1 : 0) - (searchParams.get('page') ? 1 : 0) > 0 ? "drop-down-button highlight-button" : "drop-down-button"}>
                            <div onClick={() => setShow(!show)} className="drop-down-name filters-button flex-row-btwn">
                                <div className="filters-text"><FaFilter className="filter-icon" /> Filters {searchParams.size - (searchParams.get('sort') ? 1 : 0) - (searchParams.get('search') ? 1 : 0) - (searchParams.get('page') ? 1 : 0) > 0 ? ` (${searchParams.size - (searchParams.get('sort') ? 1 : 0) - (searchParams.get('search') ? 1 : 0) })` : ""}</div> 
                                <div className="drop-down-arrow">{show ? <FaCaretUp /> : <FaCaretDown />}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {show && <div className="filter-section-container">
                    <div className="programs-search-container">
                        <DropDown setUtilities={setShownUtilities} updateResults={updateResults} dropDownSection={"Programs"} />
                        <CheckBox text={"Include Programs not in Production"}
                            searchParamKey={"allPrograms"}
                            setUtilities={setShownUtilities}
                            updateResults={updateResults}
                        />
                    </div>
                    <CostDropDown setUtilities={setShownUtilities} updateResults={updateResults} />
                    <DropDown setUtilities={setShownUtilities} updateResults={updateResults} dropDownSection={"GPP Features"}/>
                    <DropDown setUtilities={setShownUtilities} updateResults={updateResults} dropDownSection={"Certifications"}/>
                    <DropDown setUtilities={setShownUtilities} updateResults={updateResults} dropDownSection={"Contract Lengths"}/>
                </div>}
                <div className="search-results-utility-count flex-row-btwn">
                    <div className="left-col"><SortOptions searchParams={searchParams} setUtilities={setShownUtilities} updateResults={updateResults} /></div>
                    {shownUtilities.length > 0 && <div className="middle-col"><p className="white-text">{`Showing ${(page - 1) * 50 + 1} - ${Math.min(page * 50, shownUtilities.length)} of ${shownUtilities.length} utilities`}</p></div>}
                    <div className="right-col">
                        {searchParams.size - (searchParams.get('page') ? 1 : 0) - (searchParams.get('sort') ? 1 : 0) > 0 && <div className="drop-down-button highlight-button">
                            <div className="drop-down-name white-text" onClick={() => clearFilters()}>Clear All Filters</div>
                        </div>}
                    </div>
                </div >
            </div>
            {utilities.length <= 1 && <LoadingModal />}
            {shownUtilities.length === 0 && utilities.length > 1 && <p className='utility-index-results-container' style={{ top: show ? `380px` : `150px` }}>No utilities found</p>}
            {shownUtilities.length > 0 && <Utilities show={show} shownUtilities={shownUtilities} allPrograms={allPrograms} />}
        </div>
    )
}

export default UtilityIndex;