import { useState } from "react";
import {AiFillDelete} from 'react-icons/ai'


const EditRetail = ({retail, isAdd}) => {
    const [price, setPrice] = useState(retail.prices[0][1]);
    const [link, setLink] = useState(retail.link);
    const [notesArr, setNotesArr] = useState(retail.notes);

    const addNote = () => {
        setNotesArr([...notesArr, '']);
    }

    const deleteNote = (index) => {
        setNotesArr([...notesArr.slice(0, index), ...notesArr.slice(index + 1)])
    }

    const editNote = (index, note) => {
        setNotesArr([...notesArr.slice(0, index), note, ...notesArr.slice(index + 1)])
    }

    const updateRetailDetails = e => {
        const d = new Date()
        const newRetail = {
            prices: [
                [d.toISOString(), price]
            ],
            link,
            notes: notesArr,
            lastUpdated: d.toISOString(),
            _id: retail._id
        }
        // console.log(newRetail);
    }

    return (
        <div className="edit-retail-container">
            <label>{`Price: `}
                <input type="text" 
                    value={price} 
                    onChange={(e) => setPrice(e.target.value)}></input>
            </label>
            <label>{'Link: '} 
                <input type="text"
                    value={link}
                    className="edit-link-input"
                    onChange={(e) => setLink(e.target.value)}></input>
            </label>
            <label>Notes:</label>
            {notesArr.map((note, index) => (
                <div key={`${retail._id}-${index}`} className="flex-row-btwn">
                    <textarea className="edit-textarea" value={note} onChange={(e) => editNote(index, e.target.value)}></textarea>
                    <div className="edit-button" onClick={() => deleteNote(index)}><AiFillDelete /></div>
                </div>
            ))}
            <div className="flex-row-btwn top-margin">
                <div className="button" onClick={() => addNote()}>Add Note</div>
                <div className="button" onClick={() => updateRetailDetails()}>Update</div>
            </div>
        </div>
    )
}

export default EditRetail;