import { useState } from "react";
import { CgClose } from 'react-icons/cg'
import { CiSearch } from 'react-icons/ci';

const TextSearch = ({searchText, updateSearch}) => {
    const [inputText, setInputText] = useState(searchText);
    const removeSearch = () => {
        setInputText('');
        if (searchText !== '') {
            updateSearch('');
        };
    }

    return (
        <div className="flex-row-btwn">
            <div className="search-input-container">
                <div className="search-icon"><CiSearch /></div>
                <input type="text"
                    className="search-input"
                    placeholder="Search utilities by name, eiaID, alias, program, or zip code"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}>
                </input>
                <div className="search-icon close" onClick={() => removeSearch()}><CgClose /></div>
            </div>
            <button className="drop-down-button search-button" onClick={() => updateSearch(inputText)}>
                <div className="drop-down-name">Search</div>
            </button>
        </div>
    )
}

export default TextSearch;