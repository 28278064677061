import { useSearchParams } from 'react-router-dom';
import {memo} from 'react';

// const CheckBox = ({ text, searchParamKey }) => {
const CheckBox = ({ text, searchParamKey, setUtilities, updateResults, setShowLoading}) => {
    // console.log('render checkbox', searchParamKey);
    const [searchParams, setSearchParams] = useSearchParams();
    const isChecked = searchParams.get(searchParamKey) === 'true' ? true : false;
    const allPrograms = searchParams.get("allPrograms") === 'true' ? true : false;
    const disableOutOfProd = searchParamKey === "outOfProd" && !allPrograms

    const addFilter = async (isChecked, key, value) => {
        if (isChecked) {
            setSearchParams(params => {
                params.set(key, value);
                return params;
            });
        } else {
            setSearchParams(prev => {
                prev.delete(key, value);
                if (key === "allPrograms") prev.delete('outOfProd');
                return prev;
            })
        }
        setSearchParams(prev => {
                prev.delete('page');
                return prev;
            })

        let results = await updateResults(searchParams);
        setUtilities(results);
    }

    return (
        !disableOutOfProd && <label className="drop-down-item">
            <input type="checkbox" 
                className="drop-down-checkbox"
                onChange={(e) => addFilter(e.target.checked, searchParamKey, true)}
                checked={isChecked}
                ></input>{text}
        </label>
    )
}

export default memo(CheckBox);


