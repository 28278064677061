import CheckBox from "./Checkbox";
import { memo } from 'react';

const DropDown = ({ setUtilities, updateResults, dropDownSection }) => {
    
    return (
        <div className={dropDownSection.toLowerCase().replace(' ', '')}>
            <h2>{dropDownSection}</h2>
            <div>
                {dropDowns[dropDownSection].map(item => (
                    <CheckBox
                        key={item.searchParamKey}
                        text={item.text}
                        searchParamKey={item.searchParamKey}
                        setUtilities={setUtilities}
                        updateResults={updateResults}
                    />
                ))}
            </div>
        </div>
    );
}

export default memo(DropDown); 

const dropDowns = {
    "Certifications": [
        { searchParamKey: "recs", text: "Recs Retired" },
        { searchParamKey: "revenue", text: "Revenue Neutral" },
        { searchParamKey: "bcorp", text: "B-corp" },
        { searchParamKey: "greene", text: "Green-e" }
    ],
    "Programs": [
        { searchParamKey: "retail", text: "Retail Utility" },
        { searchParamKey: "gpp", text: "Green Pricing Programs" },
        { searchParamKey: "nogpp", text: "No Green Pricing Programs" },
        { searchParamKey: "CleanChoice", text: "Clean Choice Programs" },
        { searchParamKey: "outOfProd", text: "Programs not in Production" }
    ],
    "GPP Features": [
        { searchParamKey: "blocks", text: "Programs with Blocks" },
        { searchParamKey: "credit", text: "Programs with Credit" },
        { searchParamKey: "waitlisted", text: "Waitlisted Programs" },
        { searchParamKey: "termination", text: "Programs with Termination Fee" }
    ],
    "Contract Lengths": [
        { searchParamKey: "onetime", text: "One-Time / None" },
        { searchParamKey: "monthly", text: "Monthly" },
        { searchParamKey: "3months", text: "3 Months" },
        { searchParamKey: "1year", text: "1 Year" },
        { searchParamKey: "5year", text: "5 Years" },
        { searchParamKey: "10year", text: "10 Years" }
    ]
}