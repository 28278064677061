
import { Routes, Route } from 'react-router-dom';
// import { AuthRoute, ProtectedRoute } from './components/Routes/Routes';
import UtilityIndex from './components/Utilities/Index/UtilityIndex';
import UtilityShow from './components/Utilities/Show/UtilityShow';
// import UtilityEdit from './components/Utilities/UtilityEdit';
// import Login from './components/Login/Login';

function App() {
  return (
    <Routes>
      {/* <AuthRoute exact path="/" component={UtilityIndex} /> */}
      {/* <Route path="/utilities/:utilityName/edit">
        <UtilityEdit />
      </Route> */}

      <Route path="/utilities/:utilityId" element={<UtilityShow />} />
      <Route path="/utilities" element={<UtilityIndex />} />
      <Route path="/" element={<UtilityIndex />} />
      {/* <Route path="/" element={<Login />} /> */}

    </Routes>
  );
}

export default App;