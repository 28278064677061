import { useSearchParams } from 'react-router-dom';
import { BsSortAlphaDown, BsSortAlphaDownAlt, BsSortNumericDown, BsSortNumericDownAlt } from 'react-icons/bs';


const SortItem = ({ text, isAlpha, isAsc, setUtilities, updateResults }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const order = isAsc ? "asc" : "desc";
    const searchParamValue = `${text.replace(" ", "")}-${order}`
    const isChecked = searchParams.get('sort') === searchParamValue;

    const sortUtilities = () => {
        setSearchParams(params => {
            params.set("sort", searchParamValue);
            return params;
        });


        let results = updateResults(searchParams);
        setUtilities(results);
    }

    const getIcon = () => {
        if (isAlpha && isAsc) {
            return (<BsSortAlphaDown />);
        } else if (isAlpha && !isAsc) {
            return (<BsSortAlphaDownAlt />);
        } else if (!isAlpha && isAsc) {
            return (<BsSortNumericDown />);
        } else {
            return (<BsSortNumericDownAlt />);
        }
    }

    return (
        <label className="sort-item">
            <input type="radio"
                name="sort-options"
                className="drop-down-checkbox"
                onChange={() => sortUtilities()}
                checked={isChecked}
            ></input>
            <p className='flex-row-btwn width100'>
                <span>{text}</span>
                <span className='filter-icon'>{getIcon()}</span>
            </p>
        </label>
    )
}

export default SortItem;